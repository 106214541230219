import { watch } from "vue";
import Errors from "../../../Errors";

export default {
    props: ["initialCustomers", "countries",'customersPaginated'],

    data() {

        console.log(this.customersPaginated)
        return {
            customers: this.initialCustomers,
            form: { state: "" },
            states: {},
            errors: new Errors(),
            formOpen: false,
            editing: false,
            loading: false,
        };
    },

    computed: {
      
        hasCustomer() {
            return Object.keys(this.customers).length !== 0;
        },

        firstCountry() {
            return Object.keys(this.countries)[0];
        },


        hasNoStates() {
            return Object.keys(this.states).length === 0;
        },
    
    },

    created() {
        this.changeCountry(this.firstCountry);
    },

    methods: {
        editCustomerUrl(customer) {
            console.log(customer)
            return route("account.customers.update", { id: 16 });
        },
        changeCountry(country) {
            this.form.country = country;
            this.form.state = "";

            this.fetchStates(country);
        },
        generateEmail() {
            
            if (this.form.company_name) {
                const random3Chars = Math.random().toString(36).substring(2, 5);
                const companyName = this.form.company_name.replace(/[^a-zA-Z0-9]/g, '');
                const email = `auto-${companyName.toLowerCase()}-${random3Chars}@janatrading.com`;
                // tigger the email vre
                this.$set(this.form, "email", email);
            }
        },

        async fetchStates(country) {
            const response = await axios.get(
                route("countries.states.index", { code: country })
            );

            this.$set(this, "states", response.data);
        },
        edit(customer) {
            this.formOpen = true;
            this.editing = true;
            // get first address of the customer 
            const customerAddress = customer.addresses?.[0] || {};
            console.log(customer)
            this.form = {
                id: customer.id,
                company_name: customer.company_name,
                first_name: customer.first_name,
                last_name: customer.last_name,
                email: customer.email,
                phone: customer.phone,
                tax_no: customer.tax_no,
                country: customerAddress.country,
                state: customerAddress.state,
                city: customerAddress.city,
                address_1: customerAddress.address_1,
                zip: customerAddress.zip,
            };
        },

        cancel() {
            this.editing = false;
            this.formOpen = false;

            this.errors.reset();
            this.resetForm();
        },

        save() {
            this.loading = true;

            if (this.editing) {
                this.update();
            } else {
                this.create();
            }
        },

        update() {
            axios
                .put(
                    route("account.customers.update", { id: this.form.id }),
                    this.form
                )
                .then(({ data }) => {
                    console.log(data.customer)
                    this.formOpen = false;
                    this.editing = false;

                    // find the customer in customers and update with the new data
                    // this.$set(this.customers, data.customer.id, data.customer);
                    // console.log(this.customers)

                    this.resetForm();
                    this.$notify(data.message);
                })
                .catch(({ response }) => {
                    if (response.status === 422) {
                        this.errors.record(response.data.errors);
                    }

                    this.$notify(response.data.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        create() {
            axios
                .post(route("account.customers.store"), this.form)
                .then(({ data }) => {
                    this.formOpen = false;

                    let customer = { [data.customer.id]: data.customer };

                    this.$set(this, "customers", {
                        ...this.customers,
                        ...customer,
                    });

                    this.resetForm();
                    this.$notify(data.message);
                })
                .catch(({ response }) => {
                    if (response.status === 422) {
                        this.errors.record(response.data.errors);
                    }

                    this.$notify(response.data.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        resetForm() {
            this.form = { state: "" };
        },
    },
    watch: {
        'form.company_name'(newVal, oldVal) {
            const pattern = /^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
            if (!pattern.test(newVal)) {
                this.form.company_name = oldVal; // revert to the old value if the new value fails validation
            }
        }
    },
};
