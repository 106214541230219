<template>
    <div class="product-card prd-card">
        <div class="product-card-top">
            <a :href="productUrl" class="product-image">
                <img :src="baseImage" :class="{ 'image-placeholder': !hasBaseImage }" :alt="product.name" />
            </a>

            <div class="product-card-actions">
                <button class="btn btn-wishlist" :class="{ added: inWishlist }"
                    :title="$trans('storefront::product_card.wishlist')" @click="syncWishlist">
                    <i class="la-heart" :class="inWishlist ? 'las' : 'lar'"></i>
                </button>

                <!-- <button
                    class="btn btn-compare"
                    :class="{ added: inCompareList }"
                    :title="$trans('storefront::product_card.compare')"
                    @click="syncCompareList"
                >
                    <i class="las la-random"></i>
                </button> -->
            </div>

            <ul class="list-inline product-badge">
                <li class="badge badge-danger" v-if="item.is_out_of_stock">
                    {{ $trans("storefront::product_card.out_of_stock") }}
                </li>

                <li class="badge badge-primary" v-else-if="product.is_new">
                    {{ $trans("storefront::product_card.new") }}
                </li>

                <li class="badge badge-success" v-if="item.has_percentage_special_price">
                    -{{ item.special_price_percent }}%
                </li>
            </ul>
        </div>

        <div class="product-card-middle">
            <product-rating :ratingPercent="product.rating_percent" :reviewCount="product.reviews.length">
            </product-rating>

            <a :href="productUrl" class="product-name">
                <h6>{{ product.name }} | #{{ product.id }}</h6>
            </a>

            <div class="product-price product-price-clone" v-html="item.formatted_price"></div>
        </div>

        <div class="product-card-bottom">
            <table class="table">

                <tbody>

                    <tr>
                        <td>{{ $trans("storefront::product_card.packing") }}</td>
                        <td v-html="(item.unit_value + item.unit) || '-'"></td>
                    </tr>

                    <tr v-if="item.price.amount>0">
                        <td>{{ $trans("storefront::product_card.price_per_unit") }}</td>
                        <td v-html="(item.price.amount / item.unit_value).toFixed(2)"></td>
                    </tr>
                    <tr>
                        <td>{{ $trans("storefront::product_card.expried") }}</td>
                        <td class="text-dange" v-html="item.expried_date || '-'"></td>
                    </tr>
                </tbody>
            </table>
            <!-- <div class="product-price" v-html="item.formatted_price"></div> -->
            <div :class="{ 'center-buttom-card': cartItem?.id }">
                <div class="number-picker" v-if="cartItem?.id">
                    <div class="input-group-quantity" style="margin:-20px 20px;">
                        <!-- Button for decrementing quantity -->
                        <button type="button" class="btn btn-number btn-minus"
                            @click="updateQuantity(cartItem.qty - 1)">
                            <span v-if="addingToCart" class="btn-loading"></span>
                            <span v-else>
                                -
                            </span>

                        </button>

                        <!-- Input for quantity -->
                        <input :value="cartItem.qty" type="text" min="1" :max="maxQuantity(cartItem)"
                            class="form-control input-number input-quantity" @focus="$event.target.select()" @input="($event) => {
                    if ($event.target.value === '' || isNaN($event.target.value)) {
                        $event.target.value = cartItem.qty
                        return
                    }
                    changeQuantity(Number($event.target.value))
                }" />

                        <!-- Button for incrementing quantity -->
                        <button type="button" class="btn btn-number btn-plus"
                            :disabled="isQtyIncreaseDisabled(cartItem)" @click="updateQuantity(cartItem.qty + 1)">
                            <span v-if="addingToCart" class="btn-loading"></span>
                            <span v-else>
                                +
                            </span>
                        </button>
                    </div>
                </div>
                <div v-else >
                    <button v-if="hasNoOption || item.is_out_of_stock" class="btn  btn-add-to-cart"
                        :class="{ 'btn-primary': !item.is_out_of_stock, 'btn-danger': item.is_out_of_stock }"
                        :disabled="item.is_out_of_stock" @click="add">
                        <i class="las la-cart-arrow-down"></i>
                        <span v-if="addingToCart" class="btn-loading"></span>
                        <span v-else>
                            <span v-if="item.is_out_of_stock">
                                {{ $trans("storefront::product_card.out_of_stock") }}
                            </span>
                            <span v-else>

                                {{ $trans("storefront::product_card.add_to_cart") }}
                            </span>
                        </span>
                    </button>

                    <a v-else :href="productUrl" class="btn btn-primary btn-add-to-cart">
                        <i class="las la-eye"></i>
                        {{ $trans("storefront::product_card.view_options") }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "../store";
import ProductCardMixin from "../mixins/ProductCardMixin";

export default {
    mixins: [ProductCardMixin],

    props: ["product"],

    computed: {
        item() {
            return {
                ...(this.product.variant ? this.product.variant : this.product),
            };
        },
    },
    data() {
        return {
            cartItem: false,
            cart: store.state.cart,
        };
    },
    created() {
        this.unwatchCart = this.$watch(
            () => store.state.cart,
            (newCart, oldCart) => {
                this.cart = newCart;
                this.cartItem = store.getProductFromCart(this.product.id);
            },
            { deep: true } // Enable deep watching for nested properties
        );
    },
    destroyed() {
        // Clean up the watcher to prevent memory leaks
        if (this.unwatchCart) {
            this.unwatchCart();
        }
    },
    methods: {
        async add() {
            await this.addToCart();
            this.cartItem = store.getProductFromCart();
        },
        updateQuantity(qty) {
            this.addingToCart = true;
            if (qty < 1) {
                this.removeCartItem();
                return;
            } else if (
                this.product.max_qty_per_order &&
                qty > this.product.max_qty_per_order
            ) {
                qty = this.product.max_qty_per_order;
                // notify user
                this.$notify("You can't add more than " + qty + " items");
            }

            axios
                .put(
                    route("cart.items.update", {
                        id: this.cartItem.id,
                        ...(store.hasCoupon() && {
                            coupon_code: store.getCoupon(),
                        }),
                    }),
                    {
                        qty: qty,
                    }
                )
                .then((response) => {
                    store.updateCart(response.data);
                    this.cartItem = response.data.items[this.cartItem.id];
                })
                .catch((error) => {
                    this.$notify('Something went wrong. Please try again. [ERROR_PC221]');
                }).finally(() => {
                    this.addingToCart = false;
                });
        },

        maxQuantity({ item }) {
            return item.is_in_stock && item.does_manage_stock ? item.qty : null;
        },
        isQtyIncreaseDisabled(cartItem) {
            return (
                this.maxQuantity(cartItem) !== null &&
                cartItem.qty >= cartItem.item.qty
            );
        },

        changeQuantity(qty) {

            if (isNaN(qty)) {
                qty = 1;

                this.updateQuantity(qty);

                return;
            } else if (qty < 1) {
                qty = 1;

                this.removeCartItem();

                return;
            } else if (qty > this.product.max_qty_per_order) {

                qty = this.product.max_qty_per_order;

                this.updateQuantity(qty);
                this.$notify("You can't add more than " + qty + " items")
                // alert("You can't add more than " + qty + " items")

                return;
            }

            this.cartItem.qty = qty;
            if (this.exceedsMaxStock(qty, this.cartItem)) {
                qty = this.cartItem.item.qty;
                // alert("Max stock is " + qty + " items")
                this.$notify("Max stock is " + qty + " items")
                this.updateQuantity(qty);

                return;
            }

            this.updateQuantity(qty);
        },

        removeCartItem() {
            store.removeCartItem(this.cartItem);

            axios
                .delete(
                    route("cart.items.destroy", {
                        id: this.cartItem.id,
                        ...(store.hasCoupon() && {
                            coupon_code: store.getCoupon(),
                        }),
                    })
                )
                .then((response) => {
                    store.updateCart(response.data);
                }).finally(() => {
                    this.addingToCart = false;
                });

            this.cartItem = false;
        },
        exceedsMaxStock(qty, cartItem) {
            return qty > cartItem.item.qty;
        },
    },
    watch: {
        "cartItem.qty": {
            handler: function (newCart, oldCart) {
                if (isNaN(newCart) && this.cartItem) {
                    this.cartItem.qty = oldCart || 1;
                    return
                }
                this.cartItem = store.getProductFromCart(this.product.id);
            },
            deep: true,
        },
        "store.state.cart.items": {
            handler: function (newItems, oldItems) {
                consoe.log(newItems, oldItems, 315)
                const productId = this.product.id;
                this.cartItem = store.getProductFromCart(productId);
            },
            deep: true,
        },
    },
    mounted() {
        this.cartItem = store.getProductFromCart(this.product.id); // Initialize cartItem when component is mounted
    },
};
</script>
